<template>
	<div>
		<!-- 고정해더 -->
		<div class="header">
			<div class="top top_wh">
				<h2 class="page_tit">{{ $language.common.cartel }} {{ $language.common.join }}</h2>
				<div class="util">
					<button
						@click="$emit('goBack')"
						class="close"
					>
						<i class="icon icon-close"></i>
					</button>
				</div>
			</div>
		</div>

		<div class="flex_wrap pt-50 pb-30">
			<!-- 카르텔 가입 안내 -->
			<div class="con_body">
				<div class="container">
					<div class="row">
						<div class="sub_cartel_cover group">
							<img
								v-if="item_cartel.cartl_mobile_img_url"
								:src="item_cartel.cartl_mobile_img_url" width="100%"
							/>

							<div class="sub_cover_content clear">
								<div class="cartel_area clear">
									<div class="badge_area">
										<span class="badge_30 " :class="'badge_30_' + item_cartel.type +'_bl'">
											<em class="hide">{{ item_cartel.type }}</em>
										</span>
									</div>
									<div class="text_area">
										<div class="level">{{ item_cartel.cartl_level_name }}</div>
										<div class="member">
											멤버수<b>{{  item_cartel.current_member_count }}</b>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="sub_cartel_title group">
							<h2>{{  item_cartel.cartl_name  }}</h2>
						</div>
						<div class="sub_cartel_desc group">
							<p>{{  item_cartel.cartl_desctn }}</p>
						</div>
					</div>
				</div>
			</div>
			<!-- //카르텔 가입 안내 -->
			<!-- 가입하기 -->
			<div class="bottom_btn">
				<div class="container">
					<div class="btn_container">
						<p class="info_desc pb-20">{{ $language.warning.join }}</p>
						<div class="btn_area">
							<button
								@click="postCartelJoin"
								class="btn_l btn_fill_blue"
							>{{ $language.common.join }}</button>
						</div>
					</div>
				</div>
			</div>
			<!-- //가입하기 -->

			<Popup_confirm
				v-if="is_confirm"
				:not_cancel="true"
				@click="$emit('to', { name: 'mafia049'})"
				@cancel="is_confirm = false"
			>
				<template v-slot:title>{{ $language.common.cartel }} {{ $language.common.join }}</template>
				<template
					v-if="item_cartel.entry_approval_fg == 'Y'"
					v-slot:main-txt
				>{{ $language.cartel.needApprovalAdminCartel_01 }}</template>
				<template
					v-if="item_cartel.entry_approval_fg == 'Y'"
					v-slot:sub-txt
				>{{ $language.cartel.needApprovalAdminCartel_02 }}</template>
				<template
					v-else
					v-slot:sub-txt
				>{{ $language.cartel.completeJoinCartel }}</template>
			</Popup_confirm>
		</div>
	</div>
</template>

<script>
	import Popup_confirm from "@/view/Layout/PopupConfirm";
	export default {
		name: 'mafia050'
		,
		components: {Popup_confirm},
		props: ['user']
		, data: function(){
			return {
				program: {
					name: this.$language.common.cartel + ' ' + this.$language.common.join
					, title: this.$language.common.cartel + ' ' + this.$language.common.join
					, type: 'cartel_sub'
					, not_footer: true
					, not_header: true
				}
				, item_cartel: {}
				, is_confirm: false
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
							, post_list_page: { page_number: 1, pagerecnum: 10}
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						this.items_cartel_notice = result.data.notice_mttrs_list
						this.items_cartel_bbs = result.data.post_list

						switch (this.item_cartel.cartl_concern_sphere_code){
							case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
							case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
							case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
							case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,postCartelJoin: async function(){
				try{
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_join
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})
					if(result.success){
						this.is_confirm = true
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>